import React, { useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

import styles from "./NewTopNavigation.module.css";
import HeaderLogo from "../../../assets/NewHomeImages/icons/svg/newLogo.svg";
import svgArray from "../../../assets/NewHomeImages/icons/svg/svg";
import MobileViewNav from "./mobileVeiwNav";
import {  useSelector } from "react-redux";
import useRedux from "../../../hooks/useRedux";
import { addCommas } from "../../../fuctions";
import Cart from "../Cart/Cart";
import { Offcanvas } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { SwitchCurrency } from "../SwitchCurrency/SwitchCurrency";

const NewTopNavigation = () => {
  const navigate = useNavigate();
  const importedDivRef = useRef(null);
  const { token } = useSelector((state) => state.user);
  const cart = useSelector((state) => state.products.cart);
  const { country } = useSelector((state) => state.user);
  const { show, handleClose, handleShow } = useRedux();
  const loggedInUser = useSelector((state) => state.user.isLoggedIn);

  const ShopCart = () =>{
      return (
        <button onClick={handleShow} className="border-0 p-0 bg-transparent position-relative">
          <img className="cart-icon" src={svgArray[12]}/>
          <span 
          className="position-absolute rounded-circle px-2 text-white fs-6"
          style={{top:"-8px", right:"-15px", backgroundColor:"#ef4444"}}>
            {total}
          </span>
        </button>
    )
  }

  const displayDiv = () => {
    if (importedDivRef.current) {
      importedDivRef.current.style.display = "block";
    }
  };

  let total = 0;
  for (const pd of cart) {
    total += Number(pd.quantity);
  }

  let totalPrice = 0;
  for (const pd of cart) {
    totalPrice =
      totalPrice +
      Number(country === "NGN" ? pd.totalNairaPrice : pd.totalPrice);
  }

  const handelClick = () => {
    if (loggedInUser) {
      navigate("/place-order");
    } else {
      navigate("/login");
    }
    handleClose();
  };

  return (
    <div className={styles.main}>
      <NavLink to="/" className={styles.imageContainer}>
        <img src={HeaderLogo} alt="Logo" />
      </NavLink>
      <nav className={styles.desktopNav}>
        <p>+234-704-800-0910</p>
        <NavLink to="/"></NavLink>
        <NavLink to="/contact-us">Contact</NavLink>
      </nav>
      <section className={styles.desktopNav}>
        {token && <img src={svgArray[10]} onClick={() => navigate("/login")} />}
        {token ? (
          <NavLink to="/dashboard">Dashboard</NavLink>
        ) : (
          <NavLink to="/login">Login</NavLink>
        )}
        {token && (
          <Dropdown>
            <Dropdown.Toggle variant="bg-success" id="dropdown-basic">
              Account
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/dashboard/profile">Account</Dropdown.Item>
              <Dropdown.Item href="/dashboard/my-orders">Orders</Dropdown.Item>
              <Dropdown.Item href="/dashboard">Saved Items</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <ShopCart />
      </section>
      <section className={styles.mobileView}>
        <ShopCart />
        <button onClick={displayDiv} 
        className="border-0 p-0 bg-transparent"
        id={styles.hamburger} >
          <img src={svgArray[18]}/>
        </button>
      </section>
      {/* Pass importedDivRef as a prop to MobileViewNav */}
      <MobileViewNav ref={importedDivRef} />
      
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        scroll={true}
        style={{ zIndex: 10000000 }}
      >
        <Offcanvas.Header closeButton className="offCanvas__header">
          <Offcanvas.Title>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M320 264l-89.6 112-38.4-44.88"
              ></path>
              <path
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M80 176a16 16 0 00-16 16v216c0 30.24 25.76 56 56 56h272c30.24 0 56-24.51 56-54.75V192a16 16 0 00-16-16zm80 0v-32a96 96 0 0196-96h0a96 96 0 0196 96v32"
              ></path>
            </svg>
            Shopping Cart
            
          </Offcanvas.Title>
          
          <button className="border-0 p-0 bg-transparent d-inline-block"
            onClick={handleClose}>
              <i className="fs-6 bi-x-lg" style={{opacity:"0.7"}}/>
          </button>
          
        </Offcanvas.Header>
        <Offcanvas.Body>

          <div className="d-block w-100 border-bottom pb-1 mb-4">
            <SwitchCurrency />
          </div>

          {!cart.length && (
            <div className={styles.placeholder__text}>
              <span className={styles.placeholder__image}>
                <svg
                  stroke="currentColor"
                  fill="#10b981 "
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="30px"
                  width="30px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M454.65 169.4A31.82 31.82 0 00432 160h-64v-16a112 112 0 00-224 0v16H80a32 32 0 00-32 32v216c0 39 33 72 72 72h272a72.22 72.22 0 0050.48-20.55 69.48 69.48 0 0021.52-50.2V192a31.75 31.75 0 00-9.35-22.6zM176 144a80 80 0 01160 0v16H176zm192 96a112 112 0 01-224 0v-16a16 16 0 0132 0v16a80 80 0 00160 0v-16a16 16 0 0132 0z"></path>
                </svg>
              </span>
              <h6>Your cart is empty</h6>
              <p>
                No items added in your cart. Please add product to your cart
                list.
              </p>
            </div>
          )}

          <div className={styles.cart__item__container}>
            {cart.map((pd) => (
              <Cart key={pd.item.id} pd={pd} />
            ))}
          </div>
          
          {totalPrice ?
            (
            <>
              <button
                className="d-flex justify-content-between bg-success text-white 
                p-2 my-4 rounded w-100"
                
                onClick={handelClick}
                disabled={totalPrice ? false : true}
              >
                Proceed To Checkout
                <div className="mr-5">
                  {country === "NGN"
                    ? `₦${addCommas(totalPrice)}`
                    : `$${addCommas(totalPrice)}`}
                </div>
              </button>

              <button
                className="bg-success text-white 
                p-2 mb-4 rounded w-100 text-start"
                onClick={()=>{handleClose(); window.location.assign("/#products")}}
              >
                Or add more products to the cart
              </button>
            </> )
          :
            (<button
              className="bg-success text-white 
              p-2 my-4 rounded w-100"
              onClick={()=>{handleClose(); window.location.assign("/#products")}}
            >
              Add products to the cart
            </button>)
          }
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default NewTopNavigation;
