import bluePhone from "../../../NewHomeImages/icons/svg/bluePhone.svg"
import box from "../../../NewHomeImages/icons/svg/box.svg"
import cart from "../../../NewHomeImages/icons/svg/cart.svg"
import fabrics from "../../../NewHomeImages/icons/svg/fabrics.svg"
import foodStuff from "../../../NewHomeImages/icons/svg/foodStuff.svg"
import purpleTruck from "../../../NewHomeImages/icons/svg/purpleTruck.svg"
import whiteTruck from "../../../NewHomeImages/icons/svg/truck.svg"
import yellowCard from "../../../NewHomeImages/icons/svg/yellowCard.svg"
import greenPresent from "../../../NewHomeImages/icons/svg/greenPresent.svg"
import coffeeCup from "../../../NewHomeImages/icons/svg/coffeeCup.svg"
import userIcon from  "../../../NewHomeImages/icons/svg/userIcon.svg"
import downarrow from  "../../../NewHomeImages/icons/svg/downArrow.svg"
import topCart from  "../../../NewHomeImages/icons/svg/topCart.svg"
import herbs from  "../../../NewHomeImages/icons/svg/herbs.svg"
import mail from  "../../../NewHomeImages/icons/svg/mail.svg"
import location from  "../../../NewHomeImages/icons/svg/location.svg"
import phone from  "../../../NewHomeImages/icons/svg/phone.svg"
import sideDivImage from  "../../../NewHomeImages/icons/svg/sideDivImage.svg"
import hamburger from  "../../../NewHomeImages/icons/svg/hamburger.svg"
import searchIcon from  "../../../NewHomeImages/icons/svg/searchIcon.svg"
import switchicon from  "../../../NewHomeImages/icons/svg/switch.svg"
import nairaSign from  "../../../NewHomeImages/icons/svg/nairaSign.svg"
import whiteArrow from  "../../../NewHomeImages/icons/svg/whiteArrow.svg"
import greenArrow from  "../../../NewHomeImages/icons/svg/greenArrow.svg"



const svgArray =[
bluePhone,
box,
cart,
fabrics,
foodStuff,
purpleTruck,
whiteTruck,
yellowCard,
greenPresent,
coffeeCup,
userIcon,
downarrow,
topCart,
herbs,
mail,
phone,
location,
sideDivImage,
hamburger,
searchIcon,
switchicon,
nairaSign,
whiteArrow,
greenArrow


]

export default svgArray;