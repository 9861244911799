import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";

import { addToCart, handleCancelOrder } from "../../../redux/feathers/productsSlice";
import styles from "./../../NewHomePage/SecondSection/SecondSection.module.css";
import newCardStyle from "./NewCard.module.css";
import { addCommas } from "../../../fuctions";
import { countryFlag } from "../../../constants";


const ProductCard = ({ onClick, product }) => {
  const [isAddPrdBtnDisable, setIsAddPrdBtnDisable] = useState(false);
  const dispatch = useDispatch();
  const { image_url, title, price, id, quantity, price_naira } = product;
  const cart = useSelector((state) => state.products.cart);
  const { country, status } = useSelector((state) => state.user);

  useEffect(() => {
    const pd = cart.find((pd) => pd.item.id === id);
    if (pd) {
      setIsAddPrdBtnDisable(true);
    }else{
      setIsAddPrdBtnDisable(false);
    }
  }, [id, cart]);

  const handleAddPrdToCart = (item) => {
    if(!isAddPrdBtnDisable){
      // Add product to the cart
      const pd = {};
      pd.item = item;
      pd.quantity = 1;
      pd.totalPrice = pd.item.price;
      pd.totalNairaPrice = pd.item.price_naira;
      dispatch(addToCart(pd));
      setIsAddPrdBtnDisable(true);
      toast.success(`${item.title} has been successfully added to the cart`)
    }else{
      // Remove the product from the cart
      dispatch(handleCancelOrder(item.id));
      setIsAddPrdBtnDisable(false);
      toast(`${item.title} has been successfully removed from the cart`, {icon:"❌"})
    }
  };

  return (
    <div className={styles.productBox} key={product.id}>
      <figure>
        <button onClick={onClick}>
          <img src={product.image_url} alt={product.title}/>
        </button>
      </figure>
      <section>
        <h3>{product.title}</h3>
        <h4>
          {country === "NGN"
            ? `₦${addCommas(product.price_naira)}`
            : `$${addCommas(product.price)}`}
        </h4>
        <span>Qty : {product.quantity}</span>
        <button 
        className="mt-2"
        onClick={() => handleAddPrdToCart(product)} 
        title={`${isAddPrdBtnDisable ? "Remove from the cart" : "Add to the cart"}`}>
            
            {
              isAddPrdBtnDisable ? 
              <FontAwesomeIcon 
              icon={faMinusSquare} className="fs-5"
              style={{opacity:"0.7"}}/> : 
              <svg
                stroke="currentColor"
                fill='#10b981'
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="23px"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M460 160h-88v-12A116.13 116.13 0 00258.89 32h-5.78A116.13 116.13 0 00140 148v12H52a4 4 0 00-4 4v300a16 16 0 0016 16h384a16 16 0 0016-16V164a4 4 0 00-4-4zm-280-11c0-41.84 33.41-76.56 75.25-77A76.08 76.08 0 01332 148v12H180zm156 187h-64v64h-32v-64h-64v-32h64v-64h32v64h64z"></path>
              </svg>
            }
         
          </button>
      </section>
    </div>
  );
};


export const NewProductCard = ({onClick, product, perRow}) =>{
  const [isAddPrdBtnDisable, setIsAddPrdBtnDisable] = useState(false);
  const dispatch = useDispatch();
  const { image_url, title, price, id, quantity, price_naira } = product;
  const cart = useSelector((state) => state.products.cart);
  const { country, status } = useSelector((state) => state.user);

  useEffect(() => {
    const pd = cart.find((pd) => pd.item.id === id);
    if (pd) {
      setIsAddPrdBtnDisable(true);
    }else{
      setIsAddPrdBtnDisable(false);
    }
  }, [id, cart]);

  const handleAddPrdToCart = (item) => {
    if(!isAddPrdBtnDisable){
      // Add product to the cart
      const pd = {};
      pd.item = item;
      pd.quantity = 1;
      pd.totalPrice = pd.item.price;
      pd.totalNairaPrice = pd.item.price_naira;
      dispatch(addToCart(pd));
      setIsAddPrdBtnDisable(true);
      toast.success(`${item.title} has been successfully added to the cart`)
    }else{
      // Remove the product from the cart
      dispatch(handleCancelOrder(item.id));
      setIsAddPrdBtnDisable(false);
      toast(`${item.title} has been successfully removed from the cart`, {icon:"❌"})
    }
  };

  return (
    <div className={`${perRow}`}>
      <div className={`${newCardStyle.productBox} d-flex border p-3 mb-3 bg-white `}>

        <figure>
          <button onClick={onClick}>
            <img src={product.image_url} alt={product.title}/>
          </button>
        </figure>

        <section>
          <h3>{product.title}</h3>
          <h4>
            {country === countryFlag.ngn
              ? `₦${addCommas(product.price_naira)}`
              : `$${addCommas(product.price)}`}
          </h4>
          <p>Qty : {product.quantity}</p>
          <button 
          className="mt-2"
          onClick={() => handleAddPrdToCart(product)} 
          title={`${isAddPrdBtnDisable ? "Remove from the cart" : "Add to the cart"}`}>
              
              {
                isAddPrdBtnDisable ? 
                <FontAwesomeIcon 
                icon={faMinusSquare} className="fs-5"
                style={{opacity:"0.7"}}/> : 
                <svg
                  stroke="currentColor"
                  fill='#10b981'
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="23px"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M460 160h-88v-12A116.13 116.13 0 00258.89 32h-5.78A116.13 116.13 0 00140 148v12H52a4 4 0 00-4 4v300a16 16 0 0016 16h384a16 16 0 0016-16V164a4 4 0 00-4-4zm-280-11c0-41.84 33.41-76.56 75.25-77A76.08 76.08 0 01332 148v12H180zm156 187h-64v64h-32v-64h-64v-32h64v-64h32v64h64z"></path>
                </svg>
              }
          
            </button>
        </section>
      </div>
    </div>
  );  
};

export default ProductCard;

