import React, { useEffect, useRef, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addCommas, makeGetRequest } from "../../fuctions";
import {
  addToCart,
  loadProductsAsync,
} from "../../redux/feathers/productsSlice";
import { fetchUserCountry } from "../../redux/user/userSlice";
import ProductCard, { NewProductCard } from "../HomePage/ProductCard/ProductCard";
import DailyNeeds from "../SharedComponents/DailyNeeds/DailyNeeds";
import Footer from "../SharedComponents/Footer/Footer";
import LoadingSpinner from "../SharedComponents/LoadingSpinner/LoadingSpinner";
import styles from "./SearchResults.module.css";
import { useLocation } from 'react-router-dom';
import { componentWrapper } from "../../wrappers";
import { searchProductsEnpoint } from "../../url_declaration";
import toast from "react-hot-toast";
import "./search.css";

export function SearchResults() {
  const searchStatus = {pending:"Pending", completed:"Completed"}
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeProduct, setActiveProduct] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const [filterProduct, setFilterProduct] = useState([]);
  const cart = useSelector((state) => state.products.cart);
  const [status, setStatus] = useState(searchStatus.pending);
  const { country } = useSelector((state) => state.user);
  const location = useLocation();
  const inputRef = useRef(null);

  useEffect(() => {
    const pd = cart.find((pd) => pd.item.id === activeProduct?.id);
    if (pd) {
      setIsDisable(true);
    }
  }, [activeProduct?.id, cart]);

  function getMatchedProduct(searchParam){
    makeGetRequest(searchProductsEnpoint.replace(':param', searchParam))
    .then((responseData)=>{
      if(typeof responseData === "number"){
        setFilterProduct([]);
        toast.error('Unexpect error has occured')
        setStatus(searchStatus.completed)
      }else{
        console.log(responseData);
        // Set matched product
        setFilterProduct(responseData)
        setStatus(searchStatus.completed)
      }
    })
    .catch((reason)=>{
      console.log(reason);
      setFilterProduct([]);
      setStatus(searchStatus.completed)
    });
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const resultsParam = searchParams.get("q");
    console.log(resultsParam);

    // Get matched products
    if(resultsParam){
      getMatchedProduct(resultsParam);
    }else{
      setFilterProduct([]);
      setStatus(searchStatus.completed)
    }
  }, [location.search]);

  const handleClick = (item) => {
    const pd = {};
    pd.item = item;
    pd.quantity = 1;
    pd.totalPrice = pd.item.price;
    pd.totalNairaPrice = pd.item.price_naira;
    dispatch(addToCart(pd));
  };

  function onSearchSubmit(e){
    e.preventDefault();

    if(!inputRef.current.value){
      inputRef.current.focus();
      return
    }
    
    setStatus(searchStatus.pending);
    setFilterProduct([]);
    getMatchedProduct(inputRef.current.value)
  }

  return (
    <>
      <Container id={styles.searchResults}>

        <div className="d-flex justify-content-center mb-5 w-100"> 
            <form onSubmit={(e)=>{onSearchSubmit(e)}} 
            className="d-flex search-input mx-auto">
              <input type="search" className="py-3 w-100" 
              placeholder="Enter a product name" ref={inputRef}/>
              {
                status === searchStatus.pending ?
                <span className="spinner-border text-success my-auto ms-3 fs-2"></span>:
                <button className="bg-success text-white ms-3 px-3"
                type="submit">
                  Search
                </button>
              }
            </form>
        </div>

        <h1 className="fw-bold fs-3">Search results: {filterProduct?.length}</h1>
          {status !== searchStatus.pending && filterProduct?.length === 0 && (
            <p>Sorry there are no products here for your search 🥲</p>
          )}
        <div className="row flex-wrap mt-5">  
          {status === searchStatus.pending ? <LoadingSpinner /> :
            filterProduct?.map((product) => (
                <NewProductCard
                  onClick={() => {
                    setIsModalVisible(true);
                    setActiveProduct(product);
                  }}
                  key={product.id}
                  product={product}
                  perRow="col-sm-6 col-md-4 col-xl-3"
                />
              ))
            }
        </div>

        <Modal
          centered={true}
          show={isModalVisible}
          onHide={() => {
            setIsModalVisible(false);
            setActiveProduct({});
            setIsDisable(false);
          }}
          dialogClassName={styles.modalStyle}
        >
          <div className={styles.activeProduct}>
            <img src={activeProduct?.image_url} alt={activeProduct?.title} />

            <div className={styles.productContent}>
              <h4>{activeProduct?.title}</h4>
              <span className={styles.productStock}>
                <small>Stock:</small>
                <small>{activeProduct?.quantity}</small>
              </span>

              <p>{activeProduct?.description}</p>
              <h4>
                {" "}
                {country === "Nigeria"
                  ? `₦${addCommas(activeProduct?.price_naira)}`
                  : `$${addCommas(activeProduct?.price)}`}
              </h4>
              <span>
                <button
                  onClick={() => handleClick(activeProduct)}
                  disabled={isDisable}
                >
                  <svg
                    stroke="currentColor"
                    fill="#10b981"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    height="23px"
                    width="23px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M460 160h-88v-12A116.13 116.13 0 00258.89 32h-5.78A116.13 116.13 0 00140 148v12H52a4 4 0 00-4 4v300a16 16 0 0016 16h384a16 16 0 0016-16V164a4 4 0 00-4-4zm-280-11c0-41.84 33.41-76.56 75.25-77A76.08 76.08 0 01332 148v12H180zm156 187h-64v64h-32v-64h-64v-32h64v-64h32v64h64z"></path>
                  </svg>
                </button>
              </span>
            </div>
          </div>
        </Modal>
      </Container>
      <DailyNeeds />
      <Footer />
    </>
  );
}

export default componentWrapper(SearchResults);