import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import NewTopNavigation from "./Pages/SharedComponents/NewTopNavigation/NewTopNavigation";
import CartTracker from "./Pages/HomePage/CartTracker/CartTracker";
import { getNextUrl } from "./fuctions";

export const componentWrapper = (Component, loginIsRequired=false) =>{
    /* Add some extra features need by a component */
    function Wrapper(props){
        const [canShowPage, setCanShowPage] = useState(true);
        const token = useSelector((state) => state.user.token);
        const navigate = useNavigate();
        const loginPath = "/login";


        useEffect(()=>{
            if(loginIsRequired){
                if(token){
                    setCanShowPage(true);
                }else{
                    setCanShowPage(false);
                    toast.error("Please log in to continue");
                    setTimeout(() => {
                        toast.success('You are being taken to the login page');
                        const path = window.location.pathname;
                        const nextUrl = path === loginPath ? getNextUrl() : path;
                        navigate(`${loginPath}?next=${nextUrl}`);
                    }, 1500);
                }   
            }else{
                //
            }
        }, [token]);

        useEffect(() => {
            window.scrollTo({
              top: 0,
            });
        }, []);

        return (
            canShowPage ?
            <>
                <NewTopNavigation />
                <CartTracker />
                <Component {...props}/>
            </> : <></>
        )
    };

    return Wrapper;
}