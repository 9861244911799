import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";

import styles from "./SecondSection.module.css";
import detailStyle from "./ProductDetail.module.css";
import emptyDivBack from "../../../assets/NewHomeImages/icons/svg/emptyDivBack.png";
import shareIcon from "../../../assets/NewHomeImages/icons/svg/shareIcon.png";
import copyIcon from "../../../assets/NewHomeImages/icons/svg/copyIcon.svg";
import spiceIcon from "../../../assets/NewHomeImages/icons/png/spiceIcon.png";
import svgArray from "../../../assets/NewHomeImages/icons/svg/svg";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, loadCategoriesAsync } from "../../../redux/feathers/productsSlice";
import { updateCountry } from "../../../redux/user/userSlice";
import {
  loadProductsAsync,
} from "../../../redux/feathers/productsSlice";
import { useNavigate } from "react-router-dom";

import { addCommas, makeGetRequest } from "../../../fuctions";
import ProductCard from "../../HomePage/ProductCard/ProductCard";
import { Offcanvas } from "react-bootstrap";
import useRedux from "../../../hooks/useRedux";
import Cart from "../../SharedComponents/Cart/Cart";
import { countryFlag, currencyCode } from "../../../constants";
import LoadingSpinner from '../../SharedComponents/LoadingSpinner/LoadingSpinner';
import axios from "axios";


export const truncateTitle = (title) => {
  if (title.length > 5) {
    return title.substring(0, 6) + "...";
  } else {
    return title;
  }
};

const ProductDetailsContainer = ({
  activeProduct, isModalVisible, isDisable, setIsModalVisible, setActiveProduct, setIsDisable
}) => {

  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.user);

  const handleClick = (item) => {
    const pd = {};
    pd.item = item;
    pd.quantity = 1;
    pd.totalPrice = pd.item.price;
    pd.totalNairaPrice = pd.item.price_naira
    dispatch(addToCart(pd));
    toast.success(`${item.title} has been successfully added to the cart`);

  };

  return (
    <Modal
        centered={true}
        show={isModalVisible}
        onHide={() => {
          setIsModalVisible(false);
          setActiveProduct({});
          setIsDisable(false)
        }}
        dialogClassName={detailStyle.modalStyle}
      >
        <div className={detailStyle.activeProduct}>
          <img src={activeProduct?.image_url} alt={activeProduct?.title} />

          <div className={detailStyle.productContent}>
            <h4>{activeProduct?.title}</h4>
            <span className={detailStyle.productStock}>
              <small>Stock:</small>
              <small>{activeProduct?.quantity}</small>
            </span>

            <p>{activeProduct?.description}</p>
            <h4> {country === countryFlag.ngn ? `₦${addCommas(activeProduct?.price_naira)}` :`$${addCommas(activeProduct?.price)}`}</h4>
            <span>
              <button
                onClick={() => handleClick(activeProduct)}
                disabled={isDisable}
                title={
                  isDisable ? `${activeProduct?.title} is already in the cart` : 
                  `Add ${activeProduct?.title} to the cart`
                }
              >
                <svg
                  stroke="currentColor"
                  fill="#10b981"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="23px"
                  width="23px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M460 160h-88v-12A116.13 116.13 0 00258.89 32h-5.78A116.13 116.13 0 00140 148v12H52a4 4 0 00-4 4v300a16 16 0 0016 16h384a16 16 0 0016-16V164a4 4 0 00-4-4zm-280-11c0-41.84 33.41-76.56 75.25-77A76.08 76.08 0 01332 148v12H180zm156 187h-64v64h-32v-64h-64v-32h64v-64h32v64h64z"></path>
                </svg>
              </button>
            </span>
          </div>
        </div>
      </Modal>
  )
}

const SecondSection = () => {
  const { categories, categoriesStatus } = useSelector(
    (state) => state.products
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { show, handleClose, handleShow } = useRedux();
  const { country } = useSelector((state) => state.user);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const state = useSelector((state) => state.products);
  const cart = useSelector((state) => state.products.cart);
  const [isDisable, setIsDisable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeProduct, setActiveProduct] = useState({});
  const loggedInUser = useSelector((state) => state.user.isLoggedIn);
  const [newProducts, setnewProducts] = useState([]);
  const [nextUrl, setNexturl] = useState("");
  const [loadProdIsClicked, setLoadProdIsClicked] = useState(false);
  const productContainerRef = useRef(null)


  useEffect(() => {
    dispatch(loadProductsAsync()).then((res)=>{
      setNexturl(res?.payload?.next);
    });
    dispatch(loadCategoriesAsync());
  }, []);


  useEffect(() => {
    const pd = cart.find((pd) => pd.item.id === activeProduct?.id);
    if (pd) {
      setIsDisable(true);
    }
  }, [activeProduct?.id, cart]);

  const handelClick = () => {
    if (loggedInUser) {
      navigate("/place-order");
    } else {
      navigate("/login");
    }
    handleClose();
  };

  let total = 0;
  for (const pd of cart) {
    total = total + Number(pd.quantity);
  }

  let totalPrice = 0;
  for (const pd of cart) {
    totalPrice =
      totalPrice +
      Number(country === countryFlag.ngn ? pd.totalNairaPrice : pd.totalPrice);
  }

  const currency = [
    {
      id: 1,
      currency: "NGN",
      country: "Nigeria",
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Flag_of_Nigeria.svg/2000px-Flag_of_Nigeria.svg.png",
    },
    {
      id: 2,
      currency: "USD",
      country: "USA",
      icon: "https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg",
    },
  ];

  const handleCurrencyClick = (curr) => {
    if (selectedCountry === curr.country) {
      // If already selected, deselect it
      setSelectedCountry(null);
    } else {
      // Otherwise, select it
      setSelectedCountry(curr.country);
      // Dispatch action to update the country
      // dispatch(updateCountry(curr.country));
    }
  };

  async function onLoadBtnClick(){
    setLoadProdIsClicked(true)

    try{
      const getResp = await axios.get(nextUrl);
      const resp = getResp.data;
      const products = resp.results
      ?.filter((product) => product?.show)
      ?.map((product) => (
        <ProductCard
          onClick={() => {
            setIsModalVisible(true);
            setActiveProduct(product);
          }}
          key={product.id}
          product={product}
        />
      ));
      setNexturl(resp?.next);
      products && setnewProducts([...newProducts, ...products]);
      setLoadProdIsClicked(false);
    }catch(e){
      toast.error(e.message);
      setLoadProdIsClicked(false);
      return;
    }

  }

  console.log("productsState", state.productsState);
  return (
    <div className={styles.main}>

      <div className={styles.secondDiv}>
        <div className={styles.sideDiv}>
          <section className={styles.categories}>
            <h1>CATEGORIES.</h1>
            <ul>
              {
                // map category data
                categories.map((category) => (
                  <a href={`/categories/${category.slug}`}>
                    <img src={category.icon || spiceIcon} />
                    {category.title}
                  </a>
                ))
              }
            </ul>
          </section>

          <section className={styles.contact}>
            <h1>CONTACT US</h1>
            <ul>
              <a>
                <img src={svgArray[14]} />
                info@emcelo.com
              </a>
              <a>
                <i className="bi bi-whatsapp"></i>
                +234 704 800 0910
              </a>
              <a>
                <img src={svgArray[16]} />
                Lagos State Nigeria
              </a>
            </ul>
          </section>

          <section id={styles.popUp}>
            <figure>
              <img src={svgArray[17]} />

              <h4>Embrace Culinary Experience</h4>

              <p>
                Elevate Your Dining Experience With Emcelo's Premium Food
                Delivery Service, Bringing Top-Quality Cuisine Staright To Your
                Home & Office.
              </p>

              <a onClick={handleShow}>Order now </a>
            </figure>
          </section>
        </div>

        <div className={styles.gridDiv} id="products">
          <section className={styles.topSection}>
            <h2>PRODUCTS</h2>
            <figure className="">

              <div>
                <p>{`$ ${currencyCode.usa}`}</p>
              </div>

              <button className="bg-transparent border-0 p-0"
              onClick={() =>
                dispatch(
                  updateCountry(country === countryFlag.ngn ? countryFlag.usa : countryFlag.ngn)
                )
              }>
                {country === countryFlag.ngn ? 
                <i className="bi bi-toggle-on text-success fs-2"></i> :
                <i className="bi bi-toggle-off text-success fs-2"></i> }
              </button>

              <div>
                <p>{`₦ ${currencyCode.ngn}`}</p>
              </div>

            </figure>
          </section>

          <div className={styles.mainGrid} ref={productContainerRef}>
         
            {state.productsState
              ?.filter((product) => product?.show)
              ?.map((product) => (
                <ProductCard
                  onClick={() => {
                    setIsModalVisible(true);
                    setActiveProduct(product);
                  }}
                  key={product.id}
                  product={product}
                />
              ))}

              {newProducts.length > 0 && newProducts}
          </div>

          <div className="text-center">
            {loadProdIsClicked ? <LoadingSpinner /> :
            (nextUrl && <button className="bg-success text-white px-3 py-2 mt-4 d-inline-block"
              onClick={onLoadBtnClick}>
                Show more products
              </button>)
            }
          </div>

        </div>
      </div>
      <div className={styles.emptyDiv}>
        <section className={styles.topSection}>
          <h1>Embrace Africa with Emcelo</h1>
          <article>
            Embrace Africa with Emcelo: Journey through flavors, elegance, and
            artistry. Experience the richness of our spices, the luxury of our
            beauty products, the beauty of our fabrics, and the inspiration of
            our art. Welcome to Emcelo where Africa comes alive.
          </article>
        </section>

        <figure>
          <img src={emptyDivBack} />
        </figure>
        <figure>
          <img src={shareIcon} />
          <img src={copyIcon} />
        </figure>
      </div>
      <ProductDetailsContainer 
      activeProduct={activeProduct}
      isDisable={isDisable}
      isModalVisible={isModalVisible}
      setActiveProduct={setActiveProduct}
      setIsDisable={setIsDisable}
      setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
};

export default SecondSection;
