
import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


const CustomInput = ({ children, formikProps, formikKey, onChangeText, type, ...rest }) => {
  const TYPE_TEXT = "text";
  const TYPE_PASSWORD = "password";
  const inputStyles = {};
  const inputRef = useRef(null);
  const [passwordIsHidden, setPasswordIsHidden] = useState(true);

  if (formikProps.touched[formikKey] && formikProps.errors[formikKey]) {
    inputStyles.borderColor = "red";
  }

  // For input type password
  function managePasswordVisibility(){
    if(passwordIsHidden){
      inputRef.current.type = TYPE_TEXT;
      setPasswordIsHidden(false);
    }else{
      inputRef.current.type = TYPE_PASSWORD;
      setPasswordIsHidden(true);
    }
  }

  return (
    <FieldWrapper formikKey={formikKey} formikProps={formikProps} >
      <input
        ref={inputRef}
        onChange={formikProps.handleChange(formikKey)}
        onBlur={formikProps.handleBlur(formikKey)}
        style={inputStyles}
        type={type}
        {...rest}
      />

      {
        type===TYPE_PASSWORD ? 
        // To add a "eye" btn to the input when it is a password input
        <button 
        className="bg-transparent rounded-0 text-dark p-0" 
        onClick={managePasswordVisibility}
        style={{
          width:"auto",
          display:"inline-block",
          position:"absolute",
          top:"17px",
          right:"18px"
        }}
        type="button">
          {
            passwordIsHidden ? <FontAwesomeIcon icon={faEyeSlash} className="fs-5" /> :
            <FontAwesomeIcon icon={faEye} className="fs-5" />
          } 
        </button>
        :<></>
      }

    </FieldWrapper>
  );
};


const FieldWrapper = ({ children, formikProps, formikKey }) => {


//   if (formikProps.touched[formikKey] && formikProps.errors[formikKey]) {
//     labelStyles.color = Colors.ashShade;
//   }
  return (
    <div style={{position:"relative"}}>
      {children}
      <p style={{color: "red", marginBottom: 10}}>
        {formikProps.touched[formikKey] && formikProps.errors[formikKey]}
      </p>
    </div>
  );
};


export default CustomInput;
