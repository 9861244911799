import React from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import styles from "./mobileVeiwNav.module.css";
// import blackBox from "../../../assets/NewHomeImages/productImages/blackBox.svg"
// import blackHeart from "../../../assets/NewHomeImages/productImages/blackHeart.svg"
// import closeArrow from "../../../assets/NewHomeImages/productImages/closeArrow.svg"
import svgArray from "../../../assets/NewHomeImages/icons/svg/svg";
import "./functions"

const MobileViewNav = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const closeMobileNav = () => {
    if (ref.current) {
      ref.current.classList.add(styles.slideOut); // Add the slideOut class
      ref.current.classList.remove(styles.slideIn); // Remove the hiddenDiv class
      
      setTimeout(() => {
        ref.current.style.display = 'none'; 
      }); 
    }
  };
  

  return (
    <div className={styles.main} ref={ref} style={{ display: 'none' }}>
     
      {/* To close the menu on the bg click */}
      <div className={styles.background}
      onClick={closeMobileNav}></div>

      <div className={`${styles.hiddenDiv} ${styles.slideIn}`}>

        {/* Is not visible */}
        {/* <figure>
          <img src={closeArrow} id={styles.closeArrow} onClick={closeMobileNav}/>
        </figure> */} 

        <section className={styles.firstSection}>
          <NavLink to="/home">Home</NavLink>
          <NavLink to="/contact-us">Contact</NavLink>
          <NavLink to="/about-us">About Us</NavLink>
          <NavLink to="/categories">Products</NavLink>
        </section>

        <button onClick={() => navigate("/login")} >
          Sign-in
        </button>

        <section className={styles.lastSection}>
          <NavLink  to="/login"> 
            <img src={svgArray[10]} />My Account
          </NavLink>

          {/* Not link associate with these elements */} 
          {/* <a><img src={blackBox} />orders</a>
          <a><img src={blackHeart} />Save items</a> */}

        </section>

      </div>
  
    </div>
  );
});

export default MobileViewNav;
