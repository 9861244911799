import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateCountry } from "../../../redux/user/userSlice";
import { countryFlag, currencyCode } from "../../../constants";

export const SwitchCurrency = () =>{
    const dispatch = useDispatch();
    const { country } = useSelector((state) => state.user);
  
    return(
      <div className="d-flex">
        <div className="my-auto me-2">
          <p>{`$ ${currencyCode.usa}`}</p>
        </div>

        <button className="bg-transparent border-0 p-0"
        onClick={() =>
          dispatch(
            updateCountry(country === countryFlag.ngn ? countryFlag.usa : countryFlag.ngn)
          )
        }>
          {country === countryFlag.ngn ? 
          <i className="bi bi-toggle-on text-success fs-2"></i> :
          <i className="bi bi-toggle-off text-success fs-2"></i> }
        </button>

        <div className="my-auto ms-2">
          <p>{`₦ ${currencyCode.ngn}`}</p>
        </div>
        
      </div>
    )
  }