import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FirstSection.module.css";
import svgArray from "../../../assets/NewHomeImages/icons/svg/svg";
import { makeGetRequest, delay } from "../../../fuctions";
import { introImgEnpoint } from "../../../url_declaration";
import "./styles.css";


const FirstSection = () => {
  const [introImageData, setIntroImageData] = useState([]);
  const containerRef = useRef(null);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(()=>{
    // Get background image urls from the back-end
    (async function (){
        const result = await makeGetRequest(introImgEnpoint);
        if(typeof result !== "number"){
          setIntroImageData(result)
        }else{
          // Server error
        }
    })();
  }, []);

  useEffect(()=>{
    const allSlideEl = containerRef.current.children;
    const firstSlide = allSlideEl[0];
    const msWaitBeforeShow = 200
    const msPerSlide = 3000
    const msWaitBeforeHidde = 700 // if you change this, change the transition duration too; below, in the Slide component

    const animeImg = async () =>{
      /* Animation Process : prepare, show, hidde, clean */
      for(let r=0; r<allSlideEl.length; r++){
        const currentSlide = allSlideEl[r];
        const nextSlide = allSlideEl[r+1];

        // Remove the hidden classe if exist to prepare for the transition
        currentSlide.classList.remove('hidden')

        // Wait for 200 ms to make the transition visible
        await delay(msWaitBeforeShow);

        // Show the image by changing the left position, from left 100% to left 0
        currentSlide.classList.remove('left-100');
        currentSlide.classList.add('left-0');

        // Check if after this slide there is another slide, if so, prepare the
        // slide to be show otherwise prepare the first slide to be show
        nextSlide ? nextSlide.classList.remove('hidden') :  firstSlide.classList.remove('hidden');

        // Wait for x ms before hidding the current slide
        await delay(msPerSlide)

        // Then hidde the current slide
        currentSlide.classList.add('left--100');

        // Again Check if after this slide there is another slide, if so, show it
        // while hidding the current slide otherwise show the first slide while hidding the current slide
        nextSlide ? nextSlide.classList.remove('left-100') : firstSlide.classList.remove('left-100');
        nextSlide ? nextSlide.classList.add('left-0') : firstSlide.classList.add('left-0');

        // Wait for x ms for hidding and showing transition to be visible
        await delay(msWaitBeforeHidde)

        // Clean the current slide to make it as it initiale position, left 100% and hidden
        currentSlide.classList.remove('left-0', "left--100");
        currentSlide.classList.add('left-100', 'hidden');
      };

      requestAnimationFrame(animeImg);
    }
    
    // Call the animation initially
    const frameId = requestAnimationFrame(animeImg);

    return () => {cancelAnimationFrame(frameId)};
  }, [introImageData])

  const Slide = ({img_url, initPos}) =>{
    return (
      <div className={`${styles.bgStyle} w-full h-full absolute
      top-0 transition-all duration-700 ${initPos}`}
      style={{backgroundImage: `url(${img_url})`}}></div>
    )
  }

  let start = 0 // Do not need to be keep after re-render
  const allSlides = introImageData?.map((obj)=>{
      if(start === 0){
        start += 1
        return <Slide key={obj.title} img_url={obj.url} initPos="left-0"/> 
      }
      return <Slide key={obj.title} img_url={obj.url} initPos="left-100 hidden"/>
  })
  
  return (
    <div 
    className={`${styles.container} relative overflow-hidden`}
    >

      <div
      ref={containerRef}
      className={`${styles.bgStyle} absolute top-0 left-0 w-full h-full`}>
        {allSlides}
      </div>

      <div
      className={styles.main} 
      id={styles.main}>

          <i className={styles.absoluteText}>
            <marquee behavior="scroll" direction="left">
              . . . . . . . . .Your e-commerce mall for African indigenous products, foods and supplies
            </marquee>
          </i>
          <h1>Welcome to Emcelo</h1>
          <h4>...Your Portal for Authentic African Goods</h4>
      
          <section>
            <article>
              At Emcelo, we are passionate about bringing the 
              vibrant flavors of the rich culinary heritage of Africa 
              directly to your doorstep. Explore our curated selection 
              of premium raw foods sourced from the finest farms and producers across Africa.
            </article>
          </section>

          <figure>
            <input
              type="text"
              placeholder="Search by product name"
              required={true}
              ref={searchInputRef}
            />
            <a href=""
            onClick={(e) =>{
              e.preventDefault();
              navigate(`/search?q=${searchInputRef.current.value}`)}
            }>
              Search
            </a>
            <img src={svgArray[19]} className={styles.searchIcon} alt="Search" 
            onClick={() =>{
              navigate(`/search?q=${searchInputRef.current.value}`)}
            }/>
          </figure>

      </div>

    </div>
  );
};

export default FirstSection;
